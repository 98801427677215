import React from "react"
import Layout from "../components/Layout"
import {graphql} from "gatsby"
import blogTemplateStyles from "./blog.module.scss"
//this component handles the blur img & fade-ins
import Img from 'gatsby-image'

export default function Blog(props) {
  const data = props.data.markdownRemark;

  return (
    <Layout>
      <article className={blogTemplateStyles.blog}>
        <header>
        <div className={blogTemplateStyles.blog__column}>
            <h5>{data.frontmatter.primary_category}</h5>
            <h1>{data.frontmatter.title}</h1>
            <p>{data.frontmatter.subtitle}</p>
            <div className={blogTemplateStyles.pillBox}>
            {data.frontmatter.tags
                .split(',')
                .map(tag => tag.trim())
                .map(tag => {
                    return (<div className={blogTemplateStyles.pill}>{tag}</div>)
                })
            }
            </div>
            <hr/>
          </div>
          <div className={blogTemplateStyles.blog__column}>
                <Img
                    fluid={data.frontmatter.hero_image.childImageSharp.fluid}
                    alt={data.frontmatter.title}
                />
          </div>
        </header>
       
        <div
    className={blogTemplateStyles.blog__body}
    dangerouslySetInnerHTML={{__html: data.html}}
    />
        <div className={blogTemplateStyles.blog__footer}>
        </div>
      </article>
    </Layout>
  )
}

//dynamic page query, must occur within each post context
//$slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        author
        primary_category
        subtitle
        tags
        date(formatString: "MMMM Do, YYYY")
        hero_image {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`;
